import React from 'react';
import cx from 'classnames';
import ErrorPageBg from '@assets/images/error_page-bg.jpg';
import { getLocales, getHeader, getFooter } from '@services/contentful/api';
import Layout from '@components/layout';
import SiteSearch from '@components/siteSearch';
import { showErrorMessage } from '@utils/index';

export default function ErrorComponent({
  statusCode,
  headerData,
  footerData,
  locales
}) {
  const ErrorBlock = showErrorMessage(statusCode);
  const imgContainerClasses = cx(
    'lg:align-center lg:justify-center lg:py-0 py-40 px-2 flex-col',
    '!bg-cover !from-black -mb-px border-b-0 !bg-no-repeat text-left w-full',
    'lg:px-20 font-Roman bg-black text-white lg:h-screen flex lg:portrait:!bg-[length:2300px]  lg:portrait:!bg-[top_-250px_left_-500px] lg:landscape:!bg-[top_-50px_left_0px] lg:landscape:!bg-cover',
    'sm:landscape:!bg-[length:1900px] sm:landscape:!bg-[top_-242px_left_-300px] !bg-[top_40px_left_-140px] sm:px-10',
    ' md:portrait:!bg-[length:1900px] md:portrait:!bg-[top_-250px_left_-600px] md:landscape:!bg-[length:2300px] md:landscape:!bg-[top_-250px_left_-600px]'
  );
  return (
    <Layout
      headerData={headerData}
      footerData={footerData}
      locales={locales}
      isHeroWithHeading={false}
    >
      <div
        className={imgContainerClasses}
        style={{
          background: `url(${ErrorPageBg.src})`
        }}
      >
        <div className='md:w-2/3 xl:w-1/2 tracking-wide'>
          <h1 className='text-6xl lg:text-8xl'>Power&rsquo;s out.</h1>
          <h2 className='text-3xl lg:text-[34px] pt-5'>
            {ErrorBlock.subHeading}
          </h2>
          <p className='pt-5 text-lg lg:text-[18px] font-Roman  '>
            {ErrorBlock.body}
          </p>
        </div>

        <SiteSearch />
      </div>
    </Layout>
  );
}

export const getStaticProps = async ({ res, err }) => {
  const locales = await getLocales();
  const headerData = await getHeader();
  const footerData = await getFooter();
  const statusCode = err ? err.statusCode : 404;
  return {
    props: {
      statusCode,
      ...headerData,
      ...footerData,
      locales
    }
  };
};
